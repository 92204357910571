@import "./variables.scss";
@import "./mixins.scss";
.clr {
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right !important;
}

.copyright-content {
  font-size: 10px;
  margin-top: 10px;
}

.height-10 {
  height: 10px;
}

.height-15 {
  height: 15px;
}

.no-padding {
  padding: 0 !important;
}

.padding-left {
  padding: 0px 0px 0px 15px;
}

.padding-right {
  padding: 0px 15px 0px 0px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-margin {
  margin: 0;
}

.p-l-0 {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

// //
// Application Colors
// //
.primary-text-color {
  color: $primaryTextColor;
}

.text-red {
  color: $textRed;
}

.app-green {
  color: $appGreen;
}

.app-orange {
  color: $appOrange;
}

.app-grey {
  color: $gray;
}

.app-red {
  color: $red;
}

.app-light-grey {
  color: $lightGray;
}

.app-link {
  color: $lightThemeColor;
}

.app-theme {
  color: $themeColor;
}

.aligned-icon {
  &:before {
    top: 7px;
  }
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: $red;
  font-size: 12px;
  font-weight: 300;
}

.has-error .form-control {
  border-color: $red;
}

.help-block {
  font-weight: 300;
}

// //
// Events
// //
.cursor-pointer {
  cursor: pointer;
}

.hide {
  display: none;
}

.show {
  display: block;
}

// //
// Application Icons
// //
.icon-label {
  margin-left: 10px;
}

// //////
// Bootstrap Modal Changes
// //////
.modal-open {
  @media screen and (max-width: 768px) {
    position: fixed;
    width: 100%;
  }
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal-content {
  border: none;
}

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
  /* Adjusts for spacing */
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal-dialog {
  .modal-header {
    background-color: #4086c3;
    color: #fff;
    border-radius: 0;
    padding: 5px 15px;
    .modal-title {
      font-weight: 100;
      font-size: 24px;
      line-height: 40px;
      @media screen and (max-width: 572px) {
        font-size: 20px;
      }
    }
    .close {
      opacity: 1;
      background-image: url("../images/ic_close.png");
      background-repeat: no-repeat;
      background-size: 22px;
      background-position: right;
      width: 30px;
      height: 40px;
      > span {
        display: none;
      }
    }
    .has-success {
      color: limegreen;
    }
    .has-error {
      color: orangered;
    }
  }
  .modal-body {
    padding: 20px;
    @media screen and (max-width: 425px) {
      padding: 15px;
    }
    p {
      margin-bottom: 15px;
      font-size: 12px;
      font-weight: 300;
    }
  }
  .modal-footer {
    clear: both;
    border: 0;
    padding: 0px 20px 20px 20px;
    .btn {
      min-width: 100px;
      margin-left: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.primary-modal {
  .modal-content {
    border-radius: 2px;
  }
  .modal-dialog .modal-header .close {
    background-image: url("../images/ic_close_blue.png");
    background-size: 25px;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .modal-dialog .modal-header .modal-title {
    font-weight: 300;
  }
  .delete-text {
    font-weight: 300;
  }
}

// //////
// Form fields
// //////
.form-group {
  margin-bottom: 20px;
  @media screen and (max-width: 572px) {
    margin-bottom: 0px;
  }
  &:before {
    display: table;
    content: " ";
  }
  label {
    margin-bottom: 0px;
  }
}

.form-container {
  // border: 1px solid #D2D2EB;
  // border-radius: 10px;
  // background: #F5F5FA;
  // padding: 20px 20px 25px 20px;
  margin-top: 50px;
}

.input-container {
  .form-control[disabled] {
    background-color: $disabledFieldBackgroundColor !important;
    color: $gray;
  }
}

.Select.is-disabled > .Select-control {
  background-color: $disabledFieldBackgroundColor !important;
}

.date-field {
  .form-control[disabled] {
    background-color: $disabledFieldBackgroundColor !important;
    color: $disabledFieldTextColor !important;
  }
}

.rc-time-picker {
  input {
    width: 100% !important;
  }
  .icon::before {
    position: absolute;
    right: 5px;
    top: 7px;
  }
  input[disabled] {
    background-color: $disabledFieldBackgroundColor !important;
    color: $disabledFieldTextColor !important;
  }
}

input {
  width: 100%; // border: none;
  border: 1px solid #c9c9c9;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 4px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}

input:focus {
  outline: none;
}

textarea {
  width: 100%;
  border-color: #ccc;
  border-radius: 4px;
  height: 100px;
  padding: 10px;
}

textarea.form-control {
  width: 100%;
  height: 100px;
  padding: 10px;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #fff !important;
}

.date-picker-field {
  padding-left: 0px;
  padding-right: 0px;
  color: #000 !important;
  .form-control {
    border: none;
    border-radius: 0;
    box-shadow: none;
    height: 27px;
    margin-top: -4px;
    padding: 0px;
  }
  .form-control[readonly] {
    background-color: #fff;
  }
  input {
    cursor: pointer;
    width: 100%;
    line-height: 30px !important;
    margin-bottom: 5px !important;
    border-radius: 0;
    font-size: 16px !important;
    font-weight: 300 !important;
    @media screen and (max-width: 768px) {
      font-size: 14px !important;
    }
  }
}

select {
  font-size: 16px !important;
  font-weight: 300 !important;
  @media screen and (max-width: 768px) {
    font-size: 14px !important;
  }
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid $gray !important;
  border-radius: 0 !important;
  &.form-control {
    background-image: url("../images/dropdown_icon.png") !important;
    padding: 0px;
    padding-right: 25px;
  }
}

.Select {
  background: none;
  &:focus {
    outline: none;
  }
  .Select-value {
    border-color: $primaryTextColor !important;
    background: none !important;
    border-radius: 15px !important;
    font-size: 14px !important;
    line-height: 28px !important;
    span {
      border: none;
      color: $primaryTextColor;
    }
  }
}

.Select-value-icon,
.Select-value-label {
  line-height: 20px;
  height: 20px;
}

.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
  background-color: transparent !important;
}

.date-field {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid $gray !important;
  border-radius: 0px;
  padding-left: 0px;
  input {
    background-image: url("../images/ic_calendar.png");
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: right;
    font-size: 14px;
    font-weight: 300;
    padding: 0 5px;
    background-position-x: 98%;
    @media screen and (max-width: 768px) {
      font-size: 12px !important;
    }
  }
}

.time-field {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid $gray !important;
  border-radius: 0px;
  padding-left: 0px;
  input {
    background-image: url("../images/ic_time.png");
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: right;
    font-size: 14px;
    font-weight: 300;
    padding: 0 5px;
    background-position-x: 98%;
    @media screen and (max-width: 768px) {
      font-size: 12px !important;
    }
  }
}

.rdtCounter {
  .rdtBtn {
    color: $primaryTextColor;
  }
}

// //////
// Fonts
// //////
.font-11 {
  font-size: 11px;
}

.not-found-container {
  background-image: url("../../assets/images/mms-404.gif");
  background-repeat: no-repeat;
  height: calc(100vh);
  width: 100%;
  background-size: cover;
  background-position: center;
}

.error-container {
  background-image: url("../../assets/images/mms_500_mobile_vr.gif");
  background-repeat: no-repeat;
  height: calc(100vh);
  width: 100%;
  background-size: cover;
  background-position: center;
  @media screen and (min-width: 568px) {
    background-image: url("../../assets/images/mms_500_mobile_hr.gif");
  }
  @media screen and (min-width: 768px) {
    background-image: url("../../assets/images/mms_500_ipad_vr.gif");
  }
  @media screen and (min-width: 1024px) {
    background-image: url("../../assets/images/mms_500.gif");
  }
}

.switch {
  height: 25px;
  .slider.round {
    display: inline-block;
    height: 24px;
    width: 55px;
    &:before {
      width: 20px;
      height: 20px;
      bottom: 2px;
    }
  }
}

.Select-input {
  height: 28px !important;
}

.app-smaller-container {
  display: block; // height: calc(100vh);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  div {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    text-align: center;
    font-size: 20px;
    padding: 10px 20px;
  }
  .image-container {
    img {
      width: 100px;
    }
  }
  .btn {
    font-size: 18px;
  }
  .log-out-btn-container {
    margin-bottom: 20px;
    //position: absolute;
    width: 100%;
    padding: 0px;
  }
  .btn-separator {
    border-bottom: 1px solid $borderColor;
    width: 100%;
    margin-bottom: 20px;
  }
  .info-container {
    @media screen and (max-width: 320px) {
      font-size: 16px;
    }
  }
}

.fab {
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: $btnPrimaryColor;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  font-size: 30px;
  font-weight: 300;
  color: white;
  text-align: center;
  line-height: 38px;
  position: fixed;
  right: 25px;
  bottom: 80px;
}

.fab:hover {
  transform: scale(1.05);
}

.gray_dot {
  &.dark {
    border: 2px solid $gray;
  }
  display: inline-block;
  border: 2px solid $lightGray;
  border-radius: 50%;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

.vertical-bar {
  border-right: 2px solid $lightGray;
  width: 0px;
}

.text-align-center {
  text-align: center;
}

.modal-dialog {
  @media screen and (max-width: 572px) {
    width: 90%;
  }
}

.footer-content-container {
  .text {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }
  .phone-container {
    margin-top: 15px;
    .phone {
      font-size: 14px;
      font-weight: 500;
      margin-left: 10px;
    }
  }
  .email-container {
    margin-top: 15px;
    .email {
      font-size: 14px;
      font-weight: 500;
      margin-left: 10px;
    }
  }
  .icon {
    &:before {
      font-size: 16px;
      color: $primaryTextColor;
    }
  }
}

.caret {
  color: $themeColor;
}

.app-container {
  padding-top: 83px;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
  }
  .header-container {
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    z-index: 99;
  }
}

.custom-form-label {
  font-weight: 100;
  font-size: 12px;
  color: $gray;
  @media screen and (max-width: 572px) {
    margin-top: 20px;
  }
}

.multi-select {
  .Select-arrow {
    background-image: none;
    width: 0px;
    height: 0px;
    border-style: outset;
  }
  .Select-value {
    margin-top: 3px;
    & > span {
      float: right;
    }
  }
  .Select-multi-value-wrapper {
    width: 95%;
    .Select-placeholder {
      font-weight: 300;
      font-size: 14px;
    }
    .Select-value {
      .Select-value-label {
        line-height: 18px;
        font-size: 12px;
        font-weight: 300;
      }
      .Select-value-icon {
        padding-top: 0 !important;
      }
    }
  }
  .Select-clear-zone {
    display: inline-block;
    background-image: url("../../assets/images/ic_refresh_blue.png");
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: right;
    float: right;
    width: 15px;
    height: 16px;
    top: calc(50% - 9px);
    position: absolute;
    right: 30px;
  }
  .Select-clear {
    display: none;
  }
  .Select-option {
    background-image: url("../../assets/images/ic_graytick.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right;
    background-position-x: 98%;
    &.is-selected {
      background-color: #fff;
      color: $gray;
      background-image: url("../../assets/images/ic_tick_blue.png");
    }
  }
}

.answer-feedback {
  .icon:before {
    font-size: 30px;
    top: 10px;
    @media screen and (max-width: 572px) {
      font-size: 35px;
    }
  }
}

.border {
  border: 1px solid;
}

.tab-details-title {
  font-size: 20px;
  font-weight: 300;
  border-bottom: 1px solid $lightGray;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

#patient-details-tab-pane-7 {
  .tab-details-title {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
  }
}

pre {
  border: none;
  background-color: transparent;
}

.no-data-label {
  color: $lightGray;
}

.light-font {
  font-weight: 300;
}
.dark-font {
  font-weight: 500;
}

.tip {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $tooltipColor;
  position: absolute;
  top: 24px;
  left: 7px;
}

.tooltip-content {
  display: inline-block;
  width: 180px;
  background: $tooltipColor;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 0px;
  font-size: 10px;
  line-height: 14px !important;
}

.align-left {
  text-align: left !important;
}

.vertical-line-separator {
  margin: 0 5px;
  border-left: 1px solid black;
  height: 100%;
  width: 1px;
}

.btn-plain-text {
  color: $lightThemeColor;
  border: none;
  background: none;
}

.count-bubble {
  background: $lightThemeColor;
  width: 20px;
  height: 20px;
  color: white;
  margin-left: 5px;
  border-radius: 50%;
}

.outcomes-sharing-modal-body {
  height: 475px;
  overflow-y: auto;
}

.outcomes-sharing-modal {
  .navigate-button-container {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .button-explanation {
      font-size: 12px;
      &:first-of-type {
        margin-top: 10px;
      }
    }
    .button-separator {
      margin: 20px 0px;
      font-size: 16px;
      font-style: italic;
      font-weight: 100;
    }
  }
  .modal-info {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .text-block {
    display: block;
  }
  .action-button-container {
    .btn-default {
      margin-right: 20px;
    }
  }
  .history-item {
    margin-bottom: 15px;
    border-bottom: 1px solid $gray;
    padding-bottom: 15px;
    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .person {
      display: block;
      font-size: 18px;
      text-transform: capitalize;
    }
    .text-small {
      font-weight: 300;
      font-size: 13px;
    }
    .text-dark-gray,
    .vertical-pipe {
      color: $darkGray;
    }
    .vertical-pipe {
      font-weight: 300;
      font-size: 12px;
      margin: 0 4px;
    }
    .text-gray {
      color: $gray;
    }
    .text-blue {
      color: $themeColor;
    }
    .outcomes-report-message {
      font-size: 14px;
      color: black;
      margin: 14px 0;
    }
    .outcomes-report-type {
      margin-bottom: 3px;
    }
    .shared-by {
      margin-right: 6px;
    }
  }
}
