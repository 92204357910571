.navbar {
  margin-bottom: 0px !important; // @media screen and (max-width: 572px) {
  //   margin-bottom: 0px !important;
  // }
  // @media screen and (min-width: 572px) and (max-width: 736px) {
  //   margin-bottom: 5px !important;
  // }
}

.custom-nav-bar {
  .dropdown {
    float: right;
  }
  .custom-dropdown {
    padding: 0;
    .icon:before {
      top: 10px;
    }
  }
  li.nav-dropdown-scrollable > ul.dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }
  .navbar-nav>li>a {
    padding: 10px;
  }
  .navbar-header {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .mms-logo {
        height: 30px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .navbar-brand {
      padding: 10px;
      img {
        margin-left: 8px;
      }
    }
    .navbar-header {
      width: 50px;
      float: left;
    }
    .navbar-nav {
      margin: -58px -15px !important;
    }
  }
}

.header-container .nav {
  li {
    &:focus {
      outline: none;
    }
    a {
      line-height: 32px !important;
      &:focus {
        outline: none;
      }
    }
  }
}
