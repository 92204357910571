// Grid variables
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
// Colors
$lightGray: #c9c9c9;
$faintGrey: #f7f2f2;
$gray: #9b9b9b;
$red: #f4787d;
$darkRed: #cd404d;
$beige: #fbf6ec;
$darkBeige: #dbceb6;
$darkBrown: #8b6825;
$purple: #9c90ec;
$white: #ffffff;
$black: #000000;
$lightWhite: #f5f5fa;
$infoBackground: #efefef;
$createTaskInfoBackground: #fff6ea;
$themeColor: #2072b9;
$lightThemeColor: #00b2d0;
$violetColor: #8973eb;
$lightTabColor: rgba(0, 178, 208, 0.1);
$btnPrimaryColor: $lightThemeColor;
$borderGreyColor: #cecece;
$primaryTextColor: $lightThemeColor;
$disabledColor: $lightGray;
$textRed: $red;
$successColor: #bde5bc;
$errorColor: #fcdae4;
$appGreen: #71d1aa;
$appOrange: #fda429;
$facetBackground: #f4feff;
$lightBlueGreen: #f4fcfe;
$darkGreen: #4eb189;
$badgeRed: $red;
$hoverTextColor: #286090;
$lightBlue: #e4eff6;
$hoverColor: #e6edf3;
$defaultBtnHoverColor: #0093ac;
$darkGray: #4a4a4a;
$orangeColor: #e68000;
$blueBorderColor: #4a90e2;
$preLoginImageBackground: #caeaf6;
$activeColor: #bcd0e2;
$mrnCodeBorderColor: #b5d4dc;
$mrnCodeBackgroundColor: #edfbff;
$mrnCodeTextColor: #44727f;
$masqueradingHeaderColor: #f7debd;
$borderColor: #e0e0e0;
$inputFocusedColor: #007aff;
$procedureNameOnCardColor: #2072b9;
$providerLabelColor: #8a672c;
$lightPurple: #d0c8fc;
$emptyImageTextColor: #909895;
$bodyBackgroundColor: #f5f5fa;
$infoColor: #fff6ea;
$disabledFieldBackgroundColor: #eeeeee;
$disabledFieldTextColor: #555555;
$tooltipColor: #007b92;

:export {
  lightBoxModalBackgroundColor: rgba($black, 0.2);
}
