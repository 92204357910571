@import "../../assets/stylesheets/variables.scss";

.single-select,
.multi-select {
  &__placeholder,&__single-value{
    font-weight: 400;
  }

  &__menu {
    margin: 4px 0;
  }

  &__option{
    padding: 6px 10px !important;
  }

  &__placeholder,&__single-value{
    font-weight: 300;
  }
}


.select-clear-icon{
  &.icon::before{
    font-size: 16px;
    position: relative;
    top: 2px;
    right: 6px;
    color: $primaryTextColor;
    cursor: pointer;
  }
}

.multi-select__multi-value__remove {
  &:hover, &:focus{
    background-color: transparent !important;
    color: $blueBorderColor !important;
  }
}

.multi-select__multi-value__label{
  color: $blueBorderColor !important;
}

.multi-select{
  &__option {
    display:flex !important;
    align-items: center !important;
    input.option--selector {
      width: auto;
      height: auto;
      margin: 0;
      padding: 0
    }
    // label {
    //   flex: 1;
    //   margin: 0;
    //   padding: 0;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
    &> input[type=checkbox] {
      // width:20px !important;
      // height:20px !important;
      // display: flex !important;
    }

    &> label{
      // display: flex !important;
      margin-left: 5px !important;
      padding-top: 0 !important;
    }
  }
}

.single-select__menu-list {
  font-weight: 300;
  .single-select__option {
    cursor: pointer;
    &:hover {
      font-weight: 400;
    }
  }
  .single-select__option.single-select__option--is-selected {
    color: #333;
    background-color: rgba(0, 126, 255, 0.04);
  }
}

.multi-select__option {
  label {
    span {
      @media screen and (min-width: 768px) {
        display: inline;
      }
      display: inline-block;
      &:first-of-type {
        &:before {
          color: $lightThemeColor;
          font-size: 20px;
        }
        vertical-align: middle;
        margin-right: 7px;
      }
    }
  }
}

.multi-select__option--is-selected{
  background-color: white !important;
  color: black !important;
}

.without-border {
  .single-select {
    &__control{
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $lightGray;
      box-shadow: none;
      &:hover, &:focus{
        border-bottom: 1px solid $lightThemeColor;
      }
    }

    &__indicator {
      color: $lightThemeColor;
      padding: 4px 0 4px 4px;
      &:hover,&:focus{
        color: $inputFocusedColor;
      }
    }
    &__indicator-separator{
      display: none;
    }


    &__value-container{
      padding: 0 3px;
    }
  }
}

.value-container--value-outer {
  display: flex;
  flex: 1;
  overflow: hidden;
  font-size: 15px;
  font-weight: 300;
  color: white;

  .value-container--value-label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.multi-select__control {
  border-color: $lightThemeColor !important;
  .multi-select__dropdown-indicator {
    color: $lightThemeColor;
  }
}

.has-error {
  .multi-select__control, .single-select__control {
    border-color: #FB647C !important;
  }
}
