// This file contains SCSS mixins which need more than one
// line of code to support cross browser style support.


// Flex Mixins

@mixin display-flex() {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($property) {
  -webkit-flex: $property;
  -ms-flex: $property;
  flex: $property;
}

@mixin flex-order($property) {
  -webkit-order: $property;
  -ms-flex-order: $property;
  order: $property;
}

@mixin flex-direction($property) {
  -webkit-flex-direction: $property;
  -ms-flex-direction: $property;
  flex-direction: $property;
}

@mixin flex-wrap($property) {
  -webkit-flex-wrap: $property;
  -ms-flex-wrap: $property;
  flex-wrap: $property;
}

@mixin flex-flow($property) {
  -webkit-flex-flow: $property;
  flex-flow: $property;
}

@mixin justify-content($property) {
  -webkit-justify-content: $property;
  -ms-flex-pack: $property;
  justify-content: $property;
}

@mixin align-self($property) {
  -webkit-align-self: $property;
  -ms-flex-item-align: $property;
  align-self: $property;
}

@mixin align-content($property) {
  -webkit-align-content: $property;
  -ms-flex-line-pack: $property;
  align-content: $property;
}

@mixin align-items($property) {
  -webkit-align-items: $property;
  -ms-flex-align: $property;
  align-items: $property;
}