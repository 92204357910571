@import "../../assets/stylesheets/mixins.scss";
@import "../../../src/assets/stylesheets/variables.scss";

.content-administration {
  .ui-table-container {
    max-height: calc(100vh - 250px);
    display: block;
    overflow: scroll;
    width: 100%;
    .ui-table {
      width: 100%;
      border-bottom: 1px solid $lightGray;
      table-layout: fixed;
      td, th {
        padding: 10px 15px;
      }
      .sticky-header {
        background: $white;
        color: $black;
        top: 0px;
        position: sticky;
        z-index: 2;
      }
    }
  }

  .admin-navigation-div {
    position: fixed;
    z-index: 1;
    width: calc(100% - 30px);
    left: 15px;
    right: 15px;
    background-color: white;
    .admin-navigation-section {
      border: 1px solid $lightThemeColor;
      border-radius: 4px;
      .nav-pills>.active>a {
        outline: none;
        text-decoration: none;
        color: $lightThemeColor;
        font-weight: 500;
        background: $lightTabColor;
      }
      .nav-pills>li:first-child {
        border-right: 1px solid $lightThemeColor;
      }
      .nav-pills>li>a {
        padding: 5px 15px;
        .icon-label {
          font-size: 14px;
          line-height: 2;
        }
        .icon::before{
          font-size: 18px;
        }
      }
    }
  }

  .filter-container-wrapper{
    .filters-container {
      padding:0 5px;
      .filters-label {
        font-size: 16px;
        padding: 15px 0px;
      }
      .col-xs-12{
        label{
          padding-top: 10px;
        }
      }
      .filter-wrapper{
        padding-bottom: 20px;
        label{
          font-weight: 300;
        }
      }
      .btn-wrapper {
        margin-top: 10px;
        text-align: center;
        .btn {
          margin: 5px 5px;
        }
      }
    }
  }

  .record-list-wrapper {
    border-left: 1px solid $lightGray;
    margin-top: 50px;
    .pagination {
      margin: 0;
      float: right;
      margin-right: -15px;
    }
    .add-new-link {
      color: $lightThemeColor;
      cursor: pointer;
      margin-right: 20px;
  
      .icon {
        position: relative;
        top: 3px;
        margin-right: 5px;
      }
    }
  }
  
  .location-table {
    thead {
      width: 82%;
      background-color: white;
      @media screen and (max-width: 768px){
        width: 98%;
      }
    }
  }

  table {
    margin-top: 25px;
    th {border-right: 1px solid white;}
  }

}
.super-user-toggel-holder {
  @include display-flex();
  gap: 1rem;
}

.content-error {
  font-size: 24px;
  font-weight: 600;
  margin-top: 50px;
  text-align: center;
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
 margin: 0;
}

.user-is_active-holder {
  display:  flex;
  align-items: center;
  gap: 10px;
}