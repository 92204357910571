.secondary-modal {
  .modal-content {
    border-radius: 2px;
  }
  .modal-dialog .modal-header .close {
    background-image: url("./../../../assets/images/ic_close_blue.png");
    background-size: 25px;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .modal-dialog .modal-header {
    background: #e6edf3;
    color: #000;
    padding: 5px 20px;
  }
  .modal-dialog .modal-header .modal-title {
    font-weight: 300;
  }
  .delete-text {
    font-weight: 300;
  }
}
